import React from 'react';
import { useFormikContext } from 'formik';
import { CouponType, DiscountType } from '../../../../graphql';
import { Input, RadioGroup } from '../../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { DiscountFields } from '../../../../types/coupon-data-types';
import { InfoCardSetup } from '../../../../constants/coupons';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label';

export const DiscountOff = () => {
  const { values, touched, errors, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
      <DiscountInfoCard data={InfoCardSetup[2]} />

      <div>
        <div className="font-semibold text-base mb-4 block sm:hidden">What is the discount value?</div>
        <RadioGroup {...getFieldProps('discount_type')} defaultValue={DiscountType.Percentage}>
          <RadioWithLabel
            label="Percentage off"
            value={DiscountType.Percentage}
            onClick={() => setFieldValue('discount_type', DiscountType.Percentage)}
          />
          <RadioWithLabel
            label="Fixed amount"
            value={DiscountType.Fixed}
            onClick={() => setFieldValue('discount_type', DiscountType.Fixed)}
          />
        </RadioGroup>
        {values?.discount_type && values?.discount_type === DiscountType.Percentage ? (
          <>
            <div className="flex gap-4 mt-4">
              <div className={'w-[150px]'}>
                <div className="font-medium mb-1">Percentage off</div>
                <Input
                  {...getFieldProps('discount_amount')}
                  type="number"
                  iconPosition="end"
                  icon={<Unicons.UilPercentage size={18} />}
                  error={touched.discount_amount && !!errors.discount_amount}
                  errorMessage={errors.discount_amount}
                />
              </div>
              {values.show_discount_max_cap && (
                <div className={'w-[150px]'}>
                  <div className="font-medium mb-1">Percentage off upto</div>
                  <Input
                    {...getFieldProps('discount_max_cap')}
                    type="number"
                    iconPosition="start"
                    icon={<Unicons.UilRupeeSign size={15} />}
                    error={touched.discount_max_cap && !!errors.discount_max_cap}
                    errorMessage={errors.discount_max_cap}
                  />
                </div>
              )}
            </div>
            <CheckboxWithLabel
              // label="Maximum purchase amount cap"
              label={
                values.coupon_type === CouponType.ProductAmount
                  ? 'Maximum purchase amount cap (applies to individual products only, not the entire order)'
                  : 'Maximum purchase amount cap'
              }
              value="show_discount_max_cap"
              checked={values.show_discount_max_cap}
              onClick={() => setFieldValue('show_discount_max_cap', !values.show_discount_max_cap)}
            />
          </>
        ) : (
          <div className="mt-4 w-[150px]">
            <Input
              {...getFieldProps('discount_amount')}
              type="number"
              iconPosition="start"
              icon={<Unicons.UilRupeeSign size={15} />}
              error={touched.discount_amount && !!errors.discount_amount}
              errorMessage={errors.discount_amount}
            />
          </div>
        )}
      </div>
    </div>
  );
};
