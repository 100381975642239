import * as Yup from 'yup';

export const paymentProviderSchema = (isAccountIdRequired = false) =>
  Yup.object({
    account_id: Yup.string().when([], {
      is: () => isAccountIdRequired,
      then: (schema) => schema.required('is required'),
      otherwise: (schema) => schema.notRequired()
    }),
    api_key: Yup.string().required('is required'),
    api_secret: Yup.string().required('is required')
  });

export const PaymentProviderSchema = Yup.object({
  account_id: Yup.string().required('is required'),
  api_key: Yup.string().required('is required'),
  api_secret: Yup.string().required('is required')
});
