import { CouponType } from '../../graphql';

export enum ItemsFrom {
  SPECIFIC_PRODUCTS = 'SPECIFIC_PRODUCTS',
  SPECIFIC_COLLECTION = 'SPECIFIC_COLLECTION'
}

export enum UserScope {
  ALL_USERS = 'all_users',
  SPECIFIC_USERS = 'specific_users'
}

export const addCouponsData = [
  {
    id: CouponType.CartAmount,
    title: 'Amount off Products/Order',
    desc: 'Discount specific products or collections of products / total order amount.'
  },
  {
    id: CouponType.Bxgy,
    title: 'Buy X get Y',
    desc: 'Discount products based on a customer’s purchase.'
  },
  {
    id: CouponType.PurchaseHistory,
    title: 'Purchase Driven',
    desc: 'Discount based on users’ past order patterns.',
    isNew: true
  }
  // {
  //   id: '',
  //   title: 'Free Shipping',
  //   desc: 'Offer free shipping on an order.'
  // }
];

export const formatCouponType = (couponType: any) => {
  switch (couponType) {
    case CouponType.Amount:
      return 'Amount';
    case CouponType.Bxgy:
      return 'Buy X get Y';
    case CouponType.CartAmount:
      return 'Cart Amount';
    case CouponType.Payment:
      return 'Payment';
    case CouponType.ProductAmount:
      return 'Product Amount';
    case CouponType.PurchaseHistory:
      return 'Purchase Driven';
    default:
      return '';
  }
};

export const InfoCardSetup = [
  {
    heading: 'How does the discount apply?',
    info: [
      'Coupon Code: Customers must enter this code at checkout.',
      'Automatic Discount: Customers will see this in their cart and at checkout.'
    ]
  },
  {
    heading: 'What should this discount do?',
    info: [
      'Discount on cart/products: Discount on specific products, collections of products, or on the total order amount.'
      // 'Buy X get Y: Discount on products based on a customer’s purchase.'
    ]
  },
  {
    heading: 'What is the discount value?',
    info: [
      'Percentage off: Customers get a percentage off on their purchase.',
      'Fixed amount: Customers get a fixed amount off on their purchase.'
    ]
  }
];

export const InfoCardBehaviour = [
  {
    heading: 'Customer behaviour',
    info: [
      'Use coupon code: This discount is available only to customers who have previously used this coupon.',
      'Minimum order amount: This discount is valid only for customers who have previously made at least one purchase meeting the minimum amount requirement.',
      'Minimum number of orders: This discount is offered only to customers with a minimum number of past orders.'
    ]
  },
  {
    heading: 'Behaviour period',
    info: ['The customer behavior rule applies only during the specified inclusive date range. ']
  }
];

export const InfoCardApplication = [
  {
    heading: 'What does the discount applies to?',
    info: [
      'Total order value: Customers get a discount on the total order value.',
      'Specific products: Customers get a discount on specific products only.'
    ]
  },
  {
    heading: 'Are there any purchase requirements?',
    info: [
      'No requirement: The coupon can be applied on any order.',
      ' Purchase amount: The coupon can be applied only when total cart value meets a certain amount.',
      'Purchase quantity: The coupon can be applied only when total cart items meets a certain quantity.'
    ]
  }
];

export const InfoCardBXGYApplication = [
  {
    heading: 'Customer buys',
    info: ['Buy X get Y discounts are only supported with one-time purchases.']
  },
  {
    heading: 'Customer gets',
    info: ['Customers must add the quantity of items specified below to their cart.']
  }
];

export const InfoCardUserEligibility = [
  {
    heading: 'User Eligibility',
    info: [
      'All users: This discount is applicable to all users.',
      'First time users: This discount is applicable to first time user only.',
      'Total Order Count: The discount is applicable only when the user has specific number of orders.'
    ]
  },
  {
    heading: 'User Specific',
    info: ['This discount is applicable only to users whose phone number has been uploaded.']
  }
];

export const InfoCardUsageLimit = [
  {
    heading: 'Maximum discount usage',
    info: []
  },
  {
    heading: 'Discount active period',
    info: ['Set a start and end(optional) date during which this discount will be applicable.']
  },
  {
    heading: 'Combine',
    info: ['Selecting this option allows this coupon to be combined with other discounts']
  }
];
